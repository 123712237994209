.floating {
  animation-name: floating;
  animation-duration: 3s;
  animation-iteration-count: infinite;
  animation-timing-function: ease-in-out;
  margin-left: 30px;
  margin-top: 5px;
}

.delay1 {
  animation-delay: 0ms;
}

.delay2 {
  animation-delay: 100ms;
}

.delay3 {
  animation-delay: 200ms;
}

.delay4 {
  animation-delay: 300ms;
}

@keyframes floating {
  from {
    transform: translate(0, 0px);
  }
  65% {
    transform: translate(0, 15px);
  }
  to {
    transform: translate(0, -0px);
  }
}
