body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  font-family: "Lo-Res";
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #ffff8d;
  background-color: black !important;
  height: 100%;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

@font-face {
  font-family: "Lo-Res";
  src: local("Lo-Res 9 Plus OT Wide Regular"),
    local("Lo-Res-9-Plus-OT-Wide-Regular"),
    url("./fonts/LoRes9PlusOTWide-Regular.woff2") format("woff2"),
    url("./fonts/LoRes9PlusOTWide-Regular.woff") format("woff"),
    url("./fonts/LoRes9PlusOTWide-Regular.ttf") format("truetype");
  font-weight: 400;
  font-style: normal;
  color: blanchedalmond;
}
