.App {
  /* flex: 1; */
  min-height: 100vh;
  font-size: calc(10px + 2vmin);
  /* margin: 100; */
  /* padding-top: 20%; */
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.center {
  grid-area: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;
}

#particles-js {
  z-index: -1;
  background-color: #000000;
  position:fixed;
  width:100%;
  height:100%;
  top:0px;
  left:0px;
}

section {
  height: 100vh;
  scroll-snap-align: start;
  z-index: 0;
}

.page1 {
  background-color: blanchedalmond;
  height: 100vh;
}

.page2 {
  background-color: red;
  height: 100vh;
}
